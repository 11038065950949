import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Client } from '@modules/client/models/client.model';
import { Observable } from 'rxjs';
import { Communication } from '@modules/communication/models/communication.model';
import { Query } from '@modules/client/models/query.model';
import { Action } from '@modules/client/models/action.model';
import { File } from '@shared/models/file.model';
import { map } from 'rxjs/operators';
import { Participation } from '@modules/project/models/participation.model';
import { CustomQuery } from '@modules/client/models/query-list.model';

@Injectable({
  providedIn: 'root',
})
export class ClientApiService extends ApiService<Client> {
  public activeQuery: CustomQuery;
  protected endpoint = 'client';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public search(searchTerm: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/search?search=${searchTerm}`);
  }

  public query(options: ListOptions, data?: any): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/query${this.getOptionsQuery(options)}`, data);
  }

  public communicationQuery(communicationId: string, options: ListOptions, data?: any): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${communicationId}/query${this.getOptionsQuery(options)}`, data);
  }

  public wordMerge(id: string, data: { title: string; files: File[] }): Observable<ApiIndexResult<Action>> {
    return this.apiHttp.post(`/action/${this.endpoint}/${id}`, data);
  }

  public lead(options: ListOptions = {}, projectId?: string): Observable<Communication> {
    if (projectId) {
      options.parameters = [
        {
          field: 'project_id',
          value: projectId,
        },
      ];
    }

    return this.apiHttp.get(`/lead${this.getOptionsQuery(options)}`);
  }

  public getClientProjects(options?: ListOptions): Observable<Participation[]> {
    return this.apiHttp.get<any>(`/participation${this.getOptionsQuery(options)}`).pipe(
      map((response) => {
        return response.data;
      }),
    );
  }

  public getClientEntities(clientId: string, options?: ListOptions): Observable<any> {
    return this.apiHttp.get<any>(`/${this.endpoint}/${clientId}/entities${this.getOptionsQuery(options)}`).pipe(
      map((response) => {
        return response.data;
      }),
    );
  }

  public updateClientProjects(
    id: string,
    Participation: { last_name: string; sending_type_base_data_value_id: string; participations: Participation[] },
  ): Observable<any> {
    return this.apiHttp.put<any>(`/${this.endpoint}/${id}`, Participation);
  }

  public mergeClient(clientId: string, source: string, options?: ListOptions): Observable<any> {
    return this.apiHttp.put(`/${this.endpoint}/${clientId}/merge/${source}`, options);
  }

  public exportClients(options: ListOptions, query?: Query): Observable<any> {
    return this.apiHttp.put(`/${this.endpoint}/export${this.getOptionsQuery(options)}`, query);
  }

  public downloadExport(exportId: string): Observable<Blob> {
    return this.apiHttp.get(`/${this.endpoint}/export-download/${exportId}`);
  }

  public saveQuery(data: any): Observable<Query> {
    return this.apiHttp.post('/query', data);
  }
}
