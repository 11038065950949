import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LastAction {
  name: string;
  link: string;
  visited_at: Date;
}

@Injectable({
  providedIn: 'root',
})
export class LastActionsService {
  public loadActions$: Subject<void> = new Subject<void>();

  public addAction(name: string, link: string): void {
    const actions: LastAction[] = this.getActions();

    actions.unshift({
      name,
      link,
      visited_at: new Date(),
    });

    this.setActions(actions);
  }

  public getActions(): LastAction[] {
    const actions = JSON.parse(localStorage.getItem('last-actions'));

    return actions ? actions : [];
  }

  public setActions(actions: LastAction[]): void {
    localStorage.setItem('last-actions', JSON.stringify(actions));
    this.loadActions$.next();
  }

  public sliceActions(total: number): void {
    const actions = this.getActions();

    this.setActions([...actions.slice(0, total)]);
  }
}
